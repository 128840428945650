import { Component, OnInit,ViewChild } from '@angular/core';
import { SiteService } from '../site/site.service';
import { UserManagementService } from '../user-management/user-management.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-site-admin-nav',
  templateUrl: './site-admin-nav.component.html',
  styleUrls: ['./site-admin-nav.component.css']
})
export class SiteAdminNavComponent implements OnInit {
  private storage = environment.storage;
  @ViewChild('content') content;
  @ViewChild('picker') picker: any;
  public dateRange: any;
  public isCollapsed = false;
  public isMenuCollapsed = true;
  public user: any;
  allocatedSite:any;
  public siteinfo:any;
  pdfurl:any;
  sitename:any;
  periodicDateRange : any;
  modelref : any;
  data: any;
  loading = 0;
  constructor( private router: Router,private userService: UserManagementService,private siteService: SiteService,private modalService: NgbModal) { }

  ngOnInit(): void {
    const allSites = this.userService.getSiteDetail();
    this.user = this.userService.getUserDetail();
    this.siteinfo=this.userService.getSiteDetail();
    if(allSites?.length){
      this.allocatedSite = allSites[0].site_id;
      this.pdfurl = this.siteService.getPDFUrl(this.siteinfo[0].sitepfd);
      this.sitename=this.siteinfo[0].site_name;
    }
  }

  logout() {
    this.userService.logout();
  }

}
