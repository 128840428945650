
<nav class="navbar navbar-expand-xl  boxshadow fixed-top">
    <a class="navbar-brand" routerLink="/">
      <span>
        <img class="logo" src="./assets/images/logo_new.png">
      </span>
    </a>
    <button class="navbar-toggler" (click)="isMenuCollapsed = !isMenuCollapsed">
      &#9776;
    </button>
  
    <div id="wwtp" class="collapse navbar-collapse" [ngbCollapse]="isMenuCollapsed">
        <ul class="navbar-nav ml-auto" style="margin-top: -4px;">
            <li class="nav-item mt-14px" >
                <a class="nav-link text-white"  id="static" role="button" routerLink="/mixdashboard/mix-report-list">
                  Create Report
                </a>
            </li>
            <li class="nav-item ml-1" ngbDropdown>
                <a class="nav-link" ngbDropdownToggle id="userauth" role="button">
                    <img class="" src="./assets/images/avathar.jpg" height="40"
                    style="border-radius: 50%;margin-top: -8px;height: 35px;">
                </a>
                <ul ngbDropdownMenu aria-labelledby="userauth" class="dropdown-menu bg-light top-52px logout shadow user-menus">
                    <li ngbDropdown placement="left">
                    <a class="sub-dropdown" id="library" (click)="logout()" ngbDropdownToggle><i class="fa fa-power-off"></i>
                        Logout </a>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
  </nav>
 
  