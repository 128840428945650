import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserRolesService {
  constructor() {}

  isAuthenticate(url: string, role: string) {
    if (url) {
      const userRoles = this.userRolesData();
      const currentUrl = userRoles.find((d) => url.includes(d.url));
      if (currentUrl) {
        return currentUrl.roles.length ? currentUrl.roles.includes(role) : true;
      }
    }
    return false;
  }

  userRolesData() {
    return [
      {
        url: '/site/uploads/opex',
        title: 'Upload Opex Module',
        roles: ['2', '7', '11', '8', '3','13','14'],
      },
      {
        url: '/site/uploads/process',
        title: 'Upload Process Module',
        roles: ['2', '7', '11','8', '3','13','14'],
      },
      {
        url: '/alerts/historyalert',
        title: 'History Alerts',
        roles: ['6', '2', '3', '7','11', '8', '9','14'],
      },
      {
        url: '/alerts/openalert',
        title: 'Open Alerts',
        roles: ['6', '2', '3', '7','11', '8', '9','13','14'],
      },
      {
        url: '/corporation/corporate-dashboard',
        title: 'Corporate Dashboard',
        roles: ['1', '6','14'],
      },
      {
        url: '/corporation/corporate-mixed-dashboard',
        title: 'Corporate Mixed Dashboard',
        roles: ['1', '6'],
      },
      {
        url: '/corporation/corporate-reportlist-dashboard',
        title: 'Corporate Report List Dashboard',
        roles: ['1', '6'],
      },
      {
        url: '/corporation/corporate-site',
        title: 'Corporate Report List Dashboard',
        roles: ['1', '6'],
      },
      {
        url: '/corporation/viewCorporateSite',
        title: 'Corporate Report Dashboard',
        roles: ['1', '6'],
      },
      {
        url: '/dashboard/viewOpexDashboard',
        title: 'Custome Opex Dashboard Display',
        roles: ['1', '6', '2', '7','11', '8', '3', '9','14'],
      },
      {
        url: '/dashboard/customeOpexDashboard',
        title: 'Custome Opex Dashboard',
        roles: ['1', '6', '2', '7','11', '8', '3', '9','14'],
      },
      {
        url: '/dashboard/opexreportdetail',
        title: 'Opex Module Details',
        roles: ['1', '6', '2', '7','11', '8', '3', '9','14'],
      },
      {
        url: '/dashboard/opexdashboard',
        title: 'Opex Module Dashboard',
        roles: ['1', '6', '2', '7','11', '8', '3', '9','14'],
      },
      {
        url: '/site/viewSiteDashboard',
        title: 'Custome Process Dashboard Display',
        roles: ['1', '6', '2', '7','11', '8', '3', '9','14'],
      },
      {
        url: '/site/sitedashboard',
        title: 'Custome Process Dashboard',
        roles: ['1', '6', '2', '7', '11','8', '3', '9','14'],
      },
      {
        url: '/dashboard/reportdetail',
        title: 'Report Details',
        roles: ['1', '6', '2', '7','11', '8', '3', '9','13','14'],
      },
      {
        url: '/dashboard/singlereportdetail',
        title: 'Report Details',
        roles: ['1', '6', '2', '7','11', '8', '3', '9','13','14'],
      },
      {
        url: '/dashboard/processdashboard',
        title: 'Process Dashboard',
        roles: ['1', '6', '2', '7','11', '8', '3', '9','14'],
      },
      {
        url: '/equipment/equipmentsetting',
        title: 'Equipement Setting',
        roles: ['2', '7','11', '8','14'],
      },
      {
        url: '/dashboard/addOpexDashboard',
        title: 'Custom Opex Dashboard Setting',
        roles: ['1', '2', '7','11', '8','14'],
      },
      {
        url: '/reportlibrary/editreport/opex',
        title: 'Opex Report Settings',
        roles: ['1', '2', '7','11', '8','14'],
      },
      {
        url: '/site/addsitedashboard',
        title: 'Custom Process Dashboard Settings',
        roles: ['1', '6', '2', '7', '11','8','14'],
      },
      {
        url: '/dashboard/addDashboard',
        title: 'Custom Process Dashboard Settings',
        roles: ['1'],
      },
      {
        url: '/reportlibrary/editreport/process',
        title: 'Process Report Settings',
        roles: ['1', '2', '7','11', '8','13','14'],
      },
      {
        url: '/reportlibrary/editreport/process',
        title: 'Process Report Settings',
        roles: ['1', '2', '7','11', '8','13','14'],
      },
      {
        url: '/alerts/opexalerts',
        title: '',
        roles: ['1', '2', '7','11', '8','14'],
      },
      {
        url: '/alerts/parameteralerts',
        title: 'Parameter Alerts',
        roles: ['1',  '2', '7','11', '8','14'],
      },
      {
        url: '/alerts/addmodulealerts',
        title: 'Add Module Alerts',
        roles: ['1',  '2', '7','11', '8','14'],
      },
      {
        url: '/alerts/modulealerts',
        title: 'Module Alerts',
        roles: ['1',  '2', '7','11', '8','14'],
      },
      {
        url: '/alerts/addpmalerts',
        title: 'Add PM Alerts',
        roles: ['1', '6', '2', '7','11', '8','14'],
      },
      {
        url: '/alerts/pmalerts',
        title: 'PM Alerts',
        roles: ['1', '2', '7','11', '8','14'],
      },
      {
        url: '/site/editsite',
        title: 'Site Settings',
        roles: ['1', '2', '8','14'],
      },
      {
        url: '/site/allsite',
        title: 'Site Settings',
        roles: ['1', '2','7','11', '8','14'],
      },
      {
        url: '/site/user-role',
        title: '',
        roles: ['1', '2', '7','11', '8','14'],
      },
      {
        url: '/site/allsiteuser',
        title: 'Site User Settings',
        roles: ['1', '2', '7','11', '8','14'],
      },
      {
        url: '/site/allgoogleuser',
        title: 'Site User Settings',
        roles: ['1'],
      },
      {
        url: '/corporation/add-corp-site',
        title: 'Create Corp Site',
        roles: ['1'],
      },
      {
        url: '/corporation/edit-corp-site',
        title: 'Corporation Site Settings',
        roles: ['1'],
      },
      {
        url: '/corporation/user-role',
        title: '',
        roles: ['1', '6'],
      },
      {
        url: '/corporation/manage-corp-user',
        title: 'Corporation User Settings',
        roles: ['1', '6'],
      },
      {
        url: '/corporation/edit-corp',
        title: 'Corporation Setting',
        roles: ['1', '6'],
      },
      {
        url: '/category/configcategory',
        title: 'Edit Category',
        roles: ['1'],
      },
      {
        url: '/category/newcategory',
        title: 'Create Category',
        roles: ['1'],
      },
      {
        url: '/category/editcategory',
        title: 'Category Settings',
        roles: ['1'],
      },
      {
        url: '/useradminlib/user-role',
        title: '',
        roles: ['1'],
      },
      {
        url: '/useradminlib/useradmin',
        title: 'System User Settings',
        roles: ['1'],
      },
      {
        url: '/equipment/editmodule',
        title: '',
        roles: ['1'],
      },
      {
        url: '/equipment/addmodule',
        title: '',
        roles: ['1'],
      },
      {
        url: '/safety/editmodule',
        title: '',
        roles: ['1'],
      },
      {
        url: '/safety/addmodule',
        title: '',
        roles: ['1'],
      },
      {
        url: '/opexmodule/importmodule',
        title: 'Import Opex Module',
        roles: ['1'],
      },
      {
        url: '/opexmodule/editmodule',
        title: 'Opex Module Setting',
        roles: ['1'],
      },
      {
        url: '/opexmodule/addmodule',
        title: 'Import Bulk Module',
        roles: ['1'],
      },
      {
        url: '/systemadminlibrary/genericlibrary',
        title: 'Process Module Settings',
        roles: ['1'],
      },
      {
        url: '/systemadminlibrary/addModule',
        title: 'Add Process Module',
        roles: ['1'],
      },
      {
        url: '/uploadagent/managelabusers',
        title: 'Manage Lab Users',
        roles: ['2','11'],
      },
      {
        url: '/uploadagent/managelab',
        title: 'Manage Lab Agent',
        roles: ['2','11'],
      },
      {
        url: '/uploadagent/editlab',
        title: 'Edit Lab Agent',
        roles: ['2','11'],
      },
      {
        url: '/uploadagent/editlabuser',
        title: 'Edit Lab Users',
        roles: ['2','11'],
      },
      {
        url: '/uploadagent/createlab',
        title: 'Create Lab Agent',
        roles: ['2','11'],
      },
      {
        url: '/uploadagent/createlabuser',
        title: 'Create Lab Users',
        roles: ['2','11'],
      },
      {
        url: '/site/dynamic-opex-pricing-list',
        title: 'Opex Dynamic Pricing Uploads',
        roles: ['2','11','13'],
      },
      {
        url: '/site/dynamic-opex-upload',
        title: 'Upload Dynamic Opex Pricing',
        roles: ['2','11'],
      },
      {
        url: '/site/edit-dynamic-opex-price',
        title: 'Update Dynamic Opex Pricing',
        roles: ['2','11'],
      },
      {
        url: '/mixdashboard/site-mixed-dashboard',
        title: 'Mixed Dashboard',
        roles: ['2', '11','13','14'],
      },
      {
        url: '/mixdashboard/site-reportlist-dashboard',
        title: 'Mixed Report List Dashboard',
        roles: ['2', '11','13','14'],
      },
      {
        url: '/login',
        title: 'Login',
        roles: [],
      },
      {
        url: '/',
        title: 'Home',
        roles: [],
      },
    ];
  }
}
