import { Component, OnInit,ViewChild,HostListener  } from '@angular/core';
import { SiteService } from '../site/site.service';
import { UserManagementService } from '../user-management/user-management.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ActivatedRoute,Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-multisite-user-nav',
  templateUrl: './multisite-user-nav.component.html',
  styleUrls: ['./multisite-user-nav.component.css']
})
export class MultiSiteUserNavComponent implements OnInit {
  private storage = environment.storage;
  @ViewChild('content') content;
  @ViewChild('picker') picker: any;
  public dateRange: any;
  public isCollapsed = false;
  public isMenuCollapsed = true;
  public userDetail: any;
  allocatedSite:any;
  public siteinfo:any;
  pdfurl:any;
  sitename:any;
  periodicDateRange : any;
  modelref : any;
  data: any;
  loading = 0;
  currentsite = '';
  currentsitename = '';
  constructor( private router: Router,private userService: UserManagementService,private siteService: SiteService,private modalService: NgbModal,private activeRoute: ActivatedRoute) { }

  ngOnInit(): void {
    const allSites = this.userService.getSiteDetail();
    this.userDetail = this.userService.getUserDetail();
    this.siteinfo=this.userService.getSiteDetail();
    this.currentsite=this.userService.getCurrentSite();
    this.currentsitename=this.userService.getCurrentSitename();
    if(allSites?.length){
      this.allocatedSite = this.currentsite;
      this.pdfurl = this.siteService.getPDFUrl(this.siteinfo[0].sitepfd);
      this.sitename=this.currentsitename;
    }
   
  }
  

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    //console.log('Back button pressed');
    // Add your custom logic here
   // console.log(this.router.url)
  }

  get currentUrl(){
    return this.router.url;
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  get userRle() {
      return this.userDetail?.role;
    }

  logout() {
    this.userService.logout();
  }

}
