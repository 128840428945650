<nav class="navbar navbar-expand-xl bg-custom boxshadow fixed-top">
  <a class="navbar-brand" routerLink="/">
    <span>
      <img class="logo" src="./assets/images/logo_new.png">
    </span>
  </a>
  <button class="navbar-toggler" (click)="isMenuCollapsed = !isMenuCollapsed">
    &#9776;
  </button>

  <div id="wwtp" class="collapse navbar-collapse" [ngbCollapse]="isMenuCollapsed">
    <ul class="navbar-nav ml-auto" style="margin-top: -4px;">
      <li class="nav-item" ngbDropdown>
        <a class="nav-link text-white text-uppercase" ngbDropdownToggle id="systemAdmin" role="button">
          LIBRARY <i class="fa fa-angle-down" aria-hidden="true"></i>
        </a>

        <ul ngbDropdownMenu aria-labelledby="systemAdmin"
          class="dropdown-menu bg-light top-52px shadow user-menus border-none">

          <li ngbDropdown placement="right">
            <a class="sub-dropdown" id="module" ngbDropdownToggle>Modules Settings
              <i class="fa fa-caret-right float-right mt-5px" aria-hidden="true"></i></a>
            <div class="dropdown-divider"></div>
            <div ngbDropdownMenu aria-labelledby="static"
              class="dropdown-menu bg-light user-menus  sub-dropdown-menu user_role">
          <li ngbDropdown placement="right">
            <a class="sub-dropdown" id="analysis" ngbDropdownToggle>Process Module <i
                class="fa fa-caret-right float-right mt-5px" aria-hidden="true"></i></a>
            <div ngbDropdownMenu aria-labelledby="analysis"
              class="dropdown-menu bg-light user-menus  sub-dropdown-menu drop_one">
              <a routerLink="/systemadminlibrary/addModule" ngbDropdownItem>Add Module</a>
              <div class="dropdown-divider"></div>
              <a routerLink="/systemadminlibrary/genericlibrary" ngbDropdownItem>Edit Module</a>
            </div>
          </li>
          <li ngbDropdown placement="right">
            <div class="dropdown-divider"></div>
            <a class="sub-dropdown" id="analysis" ngbDropdownToggle>OPEX Module <i
                class="fa fa-caret-right float-right mt-5px" aria-hidden="true"></i></a>
            <div ngbDropdownMenu aria-labelledby="analysis"
              class="dropdown-menu bg-light user-menus  sub-dropdown-menu drop_one one">
              <a routerLink="/opexmodule/addmodule" ngbDropdownItem>Add Module</a>
              <div class="dropdown-divider"></div>
              <a routerLink="/opexmodule/editmodule" ngbDropdownItem>Edit Module</a>
            </div>
          </li>
          <li ngbDropdown placement="right">
            <div class="dropdown-divider"></div>
            <a class="sub-dropdown" id="analysis" ngbDropdownToggle>Safety Module <i
                class="fa fa-caret-right float-right mt-5px" aria-hidden="true"></i></a>
            <div ngbDropdownMenu aria-labelledby="analysis"
              class="dropdown-menu bg-light user-menus  sub-dropdown-menu drop_one">
              <a routerLink="/safety/addmodule" ngbDropdownItem>Add Module</a>
              <div class="dropdown-divider"></div>
              <a routerLink="/safety/editmodule" ngbDropdownItem>Edit Module</a>
            </div>
          </li>
          <li ngbDropdown placement="right">
            <div class="dropdown-divider"></div>
            <a class="sub-dropdown" id="analysis" ngbDropdownToggle>Equipment Module <i
                class="fa fa-caret-right float-right mt-5px" aria-hidden="true"></i></a>
            <div ngbDropdownMenu aria-labelledby="analysis"
              class="dropdown-menu bg-light user-menus  sub-dropdown-menu drop_one">
              <a routerLink="/equipment/addmodule" ngbDropdownItem>Add Module</a>
              <div class="dropdown-divider"></div>
              <a routerLink="/equipment/editmodule" ngbDropdownItem>Edit Module</a>
            </div>
          </li>
  </div>
  </li>
  <li ngbDropdown placement="bottom-right">
    <a class="sub-dropdown" id="Corporation" routerLink="/useradminlib/useradmin">User Setting </a>
    <div class="dropdown-divider"></div>
  </li>
  <li ngbDropdown placement="bottom-right">
    <a class="sub-dropdown" routerLink="/site/allgoogleuser">Google Users Settings</a>
    <div class="dropdown-divider"></div>
  </li>
 
  <li ngbDropdown placement="bottom-right">
    <a class="sub-dropdown" id="Corporation" routerLink="/useradminlib/user-role">User Role </a>
    <div class="dropdown-divider"></div>
  </li>
  <li ngbDropdown placement="bottom-right">
    <a class="sub-dropdown" id="Corporation" routerLink="/category/editcategory">Categories Settings </a>
  </li>
  </ul>
  </li>

  <li class="nav-item" ngbDropdown>
    <a class="nav-link text-white text-uppercase" ngbDropdownToggle id="systemAdmin" role="button">
      CORP <i class="fa fa-angle-down" aria-hidden="true"></i>
    </a>
    <ul ngbDropdownMenu aria-labelledby="systemAdmin"
      class="dropdown-menu bg-light top-52px shadow border-none user-menus">
      <a ngbDropdownItem routerLink="/corporation/edit-corp">Corp Setting</a>
      <div class="dropdown-divider"></div>
      <a ngbDropdownItem routerLink="/corporation/manage-corp-user">Corp User Setting</a>
      <div class="dropdown-divider"></div>
      <a class="sub-dropdown" id="Corporation" routerLink="/corporation/user-role">Corp User Role </a>
      <div class="dropdown-divider"></div>
      <a ngbDropdownItem routerLink="/corporation/edit-corp-site"> Sites Settings</a>
    </ul>
  </li>

  <li class="nav-item" ngbDropdown>
    <a class="nav-link text-white text-uppercase" ngbDropdownToggle id="systemAdmin" role="button">
      SITE <i class="fa fa-angle-down" aria-hidden="true"></i>
    </a>
    <ul ngbDropdownMenu aria-labelledby="systemAdmin"
      class="dropdown-menu bg-light top-52px shadow border-none user-menus">
      <a ngbDropdownItem routerLink="/site/allsiteuser">Site Users Settings</a>
      <div class="dropdown-divider"></div>
      
      <a class="sub-dropdown" id="Corporation" routerLink="/site/user-role">Site User Role </a>
      <div class="dropdown-divider"></div>
      <a ngbDropdownItem routerLink="/site/allsite"> Sites Settings</a>
      <div class="dropdown-divider"></div>
      <li ngbDropdown placement="bottom-right">
        <a class="sub-dropdown" id="Dashboard" ngbDropdownToggle>Alerts Settings<i
            class="fa fa-caret-right  float-right mt-5px" aria-hidden="true"></i></a>
        <div ngbDropdownMenu aria-labelledby="Dashboard"
          class="dropdown-menu bg-light user-menus sub-dropdown-menu drop_one">
          <a ngbDropdownItem routerLink="/alerts/pmalerts"> PM Alerts</a>
          <div class="dropdown-divider"></div>
          <a ngbDropdownItem routerLink="/alerts/modulealerts"> Module Alerts</a>
          <div class="dropdown-divider"></div>
          <a ngbDropdownItem routerLink="/alerts/parameteralerts"> Parameters Alerts</a>
          <div class="dropdown-divider"></div>
          <a ngbDropdownItem routerLink="/alerts/opexalerts"> Opex Alerts</a>
        </div>
        <div class="dropdown-divider"></div>
      </li>
      <li ngbDropdown placement="right">
        <a class="sub-dropdown" id="module" ngbDropdownToggle> Dashboards
          <i class="fa fa-caret-right  float-right mt-5px" aria-hidden="true"></i>
        </a>
        <div ngbDropdownMenu aria-labelledby="static"
          class="dropdown-menu bg-light user-menus  sub-dropdown-menu drop_one">
      <li ngbDropdown placement="right">
        <a class="sub-dropdown" id="analysis" ngbDropdownToggle>Process <i class="fa fa-caret-right  float-right mt-5px"
            aria-hidden="true"></i>
        </a>
        <div class="dropdown-divider"></div>
        <div ngbDropdownMenu aria-labelledby="analysis" class="dropdown-menu bg-white user-menus  sub-dropdown-menu"
          style="left: 0px!important;top:66px;">
          <a ngbDropdownItem [routerLink]="['/reportlibrary/editreport', 'process']">Report Setting</a>
          <div class="dropdown-divider"></div>
          <a ngbDropdownItem routerLink="/dashboard/addDashboard">Custom Dashboard</a>
        </div>
      </li>
      <li ngbDropdown placement="right">
        <a class="sub-dropdown" id="analysis" ngbDropdownToggle>Opex <i class="fa fa-caret-right  float-right mt-5px"
            aria-hidden="true"></i></a>
        <div ngbDropdownMenu aria-labelledby="analysis" class="dropdown-menu bg-white user-menus  sub-dropdown-menu"
          style="left: 0px!important;top:32px;">
          <a ngbDropdownItem [routerLink]="['/reportlibrary/editreport', 'opex']">Report Setting</a>
          <div class="dropdown-divider"></div>
          <a ngbDropdownItem routerLink="/dashboard/addOpexDashboard">Custom Dashboard</a>
        </div>
      </li>
      </div>

    </li>
    <!-- <li class="nav-item" ngbDropdown>
      <div class="dropdown-divider"></div>
      <a ngbDropdownItem routerLink="/equipment/equipmentsetting">Equipment Settings</a>
    </li> -->
  </ul>
  </li>

  <li class="nav-item" ngbDropdown>
    <a class="nav-link text-white" ngbDropdownToggle id="dashboard" role="button">
      DASHBOARD <i class="fa fa-angle-down"></i>
    </a>
    <ul ngbDropdownMenu aria-labelledby="dashboard"
      class="dropdown-menu bg-light top-52px  shadow user_role border-none user-menus">
      <li ngbDropdown placement="left">
        <div ngbDropdown placement="bottom-right">
          <a class="sub-dropdown" id="Dashboard" ngbDropdownToggle>Process DB<i
              class="fa fa-caret-right  float-right mt-5px" aria-hidden="true"></i></a>
          <div ngbDropdownMenu aria-labelledby="Dashboard"
            class="dropdown-menu bg-light user-menus sub-dropdown-menu user_role drop_one">
            <a ngbDropdownItem routerLink="/dashboard/processdashboard"> Default DB</a>
            <div class="dropdown-divider"></div>
            <a ngbDropdownItem routerLink="/site/sitedashboard"> Custom DB</a>
          </div>
        </div>
        <div class="dropdown-divider"></div>
        <div ngbDropdown placement="bottom-right">
          <a class="sub-dropdown" id="Dashboard" ngbDropdownToggle>Opex Dashboard<i
              class="fa fa-caret-right  float-right mt-5px" aria-hidden="true"></i></a>
          <div ngbDropdownMenu aria-labelledby="Dashboard"
            class="dropdown-menu bg-light user-menus sub-dropdown-menu drop_one">
            <a ngbDropdownItem routerLink="/dashboard/opexdashboard"> Default DB</a>
            <div class="dropdown-divider"></div>
            <a ngbDropdownItem routerLink="/dashboard/customeOpexDashboard"> Custom DB</a>
          </div>
        </div>
        <div class="dropdown-divider"></div>
        <a ngbDropdownItem routerLink="/corporation/corporate-dashboard"> Corporate Dashboard</a>
      </li>
    </ul>
  </li>

  <!-- <li class="nav-item" ngbDropdown>
    <a class="nav-link text-white" ngbDropdownToggle id="static" role="button">
      ALERTS <i class="fa fa-angle-down" aria-hidden="true"></i>
    </a>
    <ul ngbDropdownMenu aria-labelledby="static"
      class="dropdown-menu bg-light top-52px  shadow border-none user-menus static">
      <li ngbDropdown placement="left">
        <a class="sub-dropdown" routerLink="/alerts/openalert" ngbDropdownItem>Open Alert</a>
        <div class="dropdown-divider"></div>
        <a class="sub-dropdown" routerLink="/alerts/historyalert" ngbDropdownItem>History Alert</a>
      </li>
    </ul>
  </li> -->
  <li class="nav-item ml-1" ngbDropdown>
    <a class="nav-link" ngbDropdownToggle id="userauth" role="button">
      <img class="" src="./assets/images/avathar.jpg" height="40"
        style="border-radius: 50%;margin-top: -8px;height: 35px;">
    </a>
    <ul ngbDropdownMenu aria-labelledby="userauth" class="dropdown-menu bg-light top-52px logout shadow user-menus">
      <li ngbDropdown placement="left">
        <a class="sub-dropdown" id="library" (click)="logout()" ngbDropdownToggle><i class="fa fa-power-off"></i>
          Logout </a>
      </li>
    </ul>
  </li>
  </ul>
  </div>
</nav>
