import {Component, OnInit } from '@angular/core';
import { UserManagementService } from '../user-management/user-management.service';

@Component({
  selector: 'app-executive-nav',
  templateUrl: './executive-nav.component.html',
  styleUrls: ['./executive-nav.component.css']
})
export class ExecutiveNavComponent implements OnInit {
  public isCollapsed = false;
  public isMenuCollapsed = true;
  public selectedSite = '';
  public sitesList: any[] = [];
  public siteinfo:any = [];
  ch_pass : any;
  labSidebardisplay: boolean;
  changePasswordShow : boolean;
  constructor(private userService: UserManagementService) { }

  ngOnInit(): void {
  }
  logout() {
    this.userService.logout();
  }
}
