<nav class="navbar navbar-expand-lg bg-custom boxshadow fixed-top">
  <a class="navbar-brand" routerLink="/">
    <span>
      <img class="logo" src="./assets/images/logo_new.png">
    </span>
  </a>
  <span style="font-size:18px;color:#fff;font-weight:bold;" *ngIf="currentUrl!='/'">{{sitename}}</span>
  <button class="navbar-toggler" (click)="isMenuCollapsed = !isMenuCollapsed">
    &#9776;
  </button>

  <div id="wwtp" class="collapse navbar-collapse" [ngbCollapse]="isMenuCollapsed">
    <ul class="navbar-nav ml-auto">
      <li class="nav-item mt-14px" ngbDropdown *ngIf="currentUrl!='/'">
        <a class="nav-link text-uppercase text-white" ngbDropdownToggle id="systemAdmin" role="button">
          SITE <i class="fa fa-angle-down" aria-hidden="true"></i>
        </a>
        <ul ngbDropdownMenu aria-labelledby="systemAdmin"
          class="dropdown-menu bg-light top-52px shadow user-menus border-none">
          <li class="nav-item" ngbDropdown><a ngbDropdownItem routerLink="/site/allsiteuser">Site User Setting</a></li>
          <div class="dropdown-divider"></div>
          <li class="nav-item" ngbDropdown><a ngbDropdownItem routerLink="/site/user-role">Site User Role</a></li>
          <div class="dropdown-divider"></div>
          <!-- <li *ngIf="['2', '8'].includes(userDetail.role)" class="nav-item" ngbDropdown><a ngbDropdownItem [routerLink]="['/site/editsite', allocatedSite]">Site Setting</a></li>
          <div class="dropdown-divider"></div> -->
          <li class="nav-item" ngbDropdown><a ngbDropdownItem routerLink="/manageproject">Manage Projects</a></li>
          <div class="dropdown-divider"></div>
          <div *ngIf="['2', '8'].includes(userDetail.role)" class="dropdown-divider"></div>
          <li ngbDropdown placement="bottom-right">
            <a class="sub-dropdown" id="Dashboard" ngbDropdownToggle>Alerts Settings<i
                class="fa fa-caret-right  float-right mt-5px" aria-hidden="true"></i></a>
            <div ngbDropdownMenu aria-labelledby="Dashboard"
              class="dropdown-menu bg-light user-menus sub-dropdown-menu user_role">
              <a ngbDropdownItem routerLink="/alerts/pmalerts"> PM Alerts</a>
              <div class="dropdown-divider"></div>
              <a ngbDropdownItem routerLink="/alerts/modulealerts"> Module Alerts</a>
              <div class="dropdown-divider"></div>
              <a ngbDropdownItem routerLink="/alerts/parameteralerts"> Parameters Alerts</a>
              <div class="dropdown-divider"></div>
              <a ngbDropdownItem routerLink="/alerts/opexalerts"> Opex Alerts</a>
            </div>
            <div class="dropdown-divider"></div>
          </li>
          <li ngbDropdown placement="right">
            <a class="sub-dropdown" id="module" ngbDropdownToggle> Dashboards
              <i class="fa fa-caret-right  float-right mt-5px" aria-hidden="true"></i>
            </a>
            <div class="dropdown-divider"></div>
            <div ngbDropdownMenu aria-labelledby="static"
              class="dropdown-menu bg-light user-menus  sub-dropdown-menu user_role">
                    <li ngbDropdown placement="right">
                      <a class="sub-dropdown" id="analysis" ngbDropdownToggle>Process <i
                          class="fa fa-caret-right  float-right mt-5px" aria-hidden="true"></i>
                      </a>
                      <div class="dropdown-divider"></div>
                      <div ngbDropdownMenu aria-labelledby="analysis"
                        class="dropdown-menu bg-white user-menus  sub-dropdown-menu">


                        <a ngbDropdownItem routerLink="/reportlibrary/editreport/process">Report Setting</a>
                        <div class="dropdown-divider"></div>
                        <a ngbDropdownItem routerLink="/site/addsitedashboard">Custom Dashboard</a>
                      </div>
                    </li>
                    <li ngbDropdown placement="right">
                      <a class="sub-dropdown" id="analysis" ngbDropdownToggle>Opex <i
                          class="fa fa-caret-right  float-right mt-5px" aria-hidden="true"></i></a>
                      <div ngbDropdownMenu aria-labelledby="analysis"
                        class="dropdown-menu bg-white user-menus  sub-dropdown-menu">
                        <a ngbDropdownItem routerLink="/reportlibrary/editreport/opex">Report Setting</a>
                        <div class="dropdown-divider"></div>
                        <a ngbDropdownItem routerLink="/dashboard/addOpexDashboard">Custom Dashboard</a>
                      </div>
                    </li>
                   
                   
            </div>
          </li>
          <li class="nav-item" ngbDropdown><a ngbDropdownItem routerLink="/equipment/equipmentsetting">Equipment Settings</a>
          </li>
          <div class="dropdown-divider"></div>
      
          <li ngbDropdown placement="bottom-right" *ngIf="['2','11'].includes(userDetail?.role)">
            <a class="sub-dropdown" id="Dashboard" ngbDropdownToggle>Manual Data Source<i
                class="fa fa-caret-right  float-right mt-5px" aria-hidden="true"></i></a>
            <div ngbDropdownMenu aria-labelledby="Dashboard"
              class="dropdown-menu bg-light user-menus sub-dropdown-menu user_role">
              <a ngbDropdownItem routerLink="/uploadagent/managelab"> Manage Sources</a>
              <div class="dropdown-divider"></div>
              <a ngbDropdownItem routerLink="/uploadagent/managelabusers"> Manage Users</a>
            
            </div>
          </li>
        </ul>
      </li>
      <li class="nav-item mt-14px" ngbDropdown *ngIf="currentUrl!='/'">
        <a class="nav-link text-white" ngbDropdownToggle id="dashboard" role="button">
          DASHBOARD <i class="fa fa-angle-down" aria-hidden="true"></i>
        </a>
        <ul ngbDropdownMenu aria-labelledby="dashboard"
          class="dropdown-menu bg-light top-52px  shadow user_role border-none user-menus static">
          <li ngbDropdown placement="left">
            <a class="sub-dropdown" id="Dashboard" ngbDropdownToggle>Process DB<i
                class="fa fa-caret-right  float-right mt-5px" aria-hidden="true"></i></a>
            <div class="dropdown-divider"></div>
            <div ngbDropdownMenu aria-labelledby="Dashboard"
              class="dropdown-menu bg-light user-menus sub-dropdown-menu user_role sub_opex_drop">
              <a ngbDropdownItem routerLink="/dashboard/processdashboard">Default DB</a>
              <div class="dropdown-divider"></div>
              <a ngbDropdownItem routerLink="/site/sitedashboard"> Custom DB</a>
            </div>
          </li>

          <li ngbDropdown placement="left">
            <a class="sub-dropdown" id="Dashboard" ngbDropdownToggle>Opex Dashboard<i
                class="fa fa-caret-right  float-right mt-5px" aria-hidden="true"></i></a>
                <div class="dropdown-divider"></div>
            <div ngbDropdownMenu aria-labelledby="Dashboard"
              class="dropdown-menu bg-light user-menus sub-dropdown-menu user_role sub_opex_drop">
              <a ngbDropdownItem routerLink="/dashboard/opexdashboard"> Default DB</a>
              <div class="dropdown-divider"></div>
              <a ngbDropdownItem routerLink="/dashboard/customeOpexDashboard"> Custom DB</a>
            </div>
          </li>
          <li ngbDropdown placement="left">
            <a class="sub-dropdown" routerLink="/mixdashboard/mix-report-list" ngbDropdownToggle >Mix Report </a>
            
          </li>
          <div *ngIf="['40'].includes(allocatedSite)" class="dropdown-divider"></div>
          <li ngbDropdown placement="left">
            <a class="sub-dropdown" *ngIf="['40'].includes(allocatedSite)" routerLink="/periodicreport" ngbDropdownToggle >Periodic Report </a> 
            <!-- <a *ngIf="['40'].includes(allocatedSite)" class="sub-dropdown" href="javascript:void(0)" (click)="getViewDetails(allocatedSite)" ngbDropdownToggle >Periodic Report</a> -->
          </li>
        </ul>
      </li>
      <li class="nav-item mt-14px" ngbDropdown *ngIf="currentUrl!='/'">
        <a class="nav-link text-white" ngbDropdownToggle id="static" role="button">
          ALERTS <i class="fa fa-angle-down" aria-hidden="true"></i>
        </a>
        <ul ngbDropdownMenu aria-labelledby="static"
          class="dropdown-menu bg-light top-52px  shadow border-none user-menus static">
          <li ngbDropdown placement="left">
            <a class="sub-dropdown" routerLink="/alerts/openalert" ngbDropdownItem>Open Alert</a>
            <div class="dropdown-divider"></div>
            <a class="sub-dropdown" routerLink="/alerts/historyalert" ngbDropdownItem>History Alert</a>
          </li>
        </ul>
      </li>
      <li class="nav-item mt-14px" ngbDropdown *ngIf="currentUrl!='/'">
        <a class="nav-link text-white" ngbDropdownToggle id="static" role="button">
          UPLOADS <i class="fa fa-angle-down" aria-hidden="true"></i>
        </a>
        <ul ngbDropdownMenu aria-labelledby="static"
          class="dropdown-menu bg-light top-52px  shadow border-none user-menus static">
          <li ngbDropdown placement="left">
            <a class="sub-dropdown" routerLink="/site/uploads/process" ngbDropdownItem>Process Module</a>
            <div class="dropdown-divider"></div>
            <a class="sub-dropdown" routerLink="/site/uploads/opex" ngbDropdownItem>Opex Module</a>
            <!-- <div class="dropdown-divider"></div>
            <a class="sub-dropdown" routerLink="/site/dynamic-opex-pricing-list" ngbDropdownItem>Opex Dynamic Pricing</a> -->
          </li>
        </ul>
      </li>
      
      
      
      <!-- <li> <a class="nav-link"><i class="fa fa-plus-square mt-10px"></i> </a></li> -->
      <li class="nav-item mt-14px" *ngIf="siteinfo && siteinfo[0].sitepfd && ['2','3', '7','11', '8', '9','14'].includes(userDetail?.role)" ngbDropdown>
        <a class="nav-link text-white" ngbDropdownToggle id="static" role="button" routerLink="/site/pdfviewer">
          View PFD
        </a>
      </li>
      <li class="nav-item ml-1" ngbDropdown>
        <a class="nav-link" ngbDropdownToggle id="userauth" role="button">
          <img class="" src="./assets/images/avathar.jpg" height="40"
            style="border-radius: 50%;margin-top: 6px;height: 35px;">
        </a>
        <ul ngbDropdownMenu aria-labelledby="userauth" class="dropdown-menu bg-light top-52px logout shadow user-menus">
          <li ngbDropdown placement="left">
            <a class="sub-dropdown" id="logout" (click)="logout()" ngbDropdownToggle><i class="fa fa-power-off"></i>
              Logout </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</nav>

