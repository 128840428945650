import { Component, OnInit,ViewChild } from '@angular/core';
import { SiteService } from 'src/app/site/site.service';
import { UserManagementService } from '../user-management/user-management.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-periodic-report',
  templateUrl: './periodic-report.component.html',
  styleUrls: ['./periodic-report.component.css']
})
export class PeriodicReportComponent implements OnInit {
  @ViewChild('content') content;
  @ViewChild('picker') picker: any;
  public dateRange: any;
  private storage = environment.storage;
  public user: any;
  allocatedSite:any;
  public siteinfo:any;
  allReports: any;
  loading = 0;
  periodicDateRange : any;
  modelref : any;
  rowsPerPageOptions = [5, 10, 20, 30, 40, 50, 100];
  colspan = 6;
  constructor( private router: Router,private userService: UserManagementService,private siteService: SiteService,private modalService: NgbModal) { }

  ngOnInit(): void {
    const allSites = this.userService.getSiteDetail();
    this.user = this.userService.getUserDetail();
    this.siteinfo=this.userService.getSiteDetail();
    if(allSites?.length){
      this.allocatedSite = allSites[0].site_id;
    }
    this.getPeriodicReport();
  }
  getViewDetails(site_id){
    this.storage.setItem('periodic_request', '');
   this.modelref =  this.modalService.open(this.content, { centered: true });
  }
  periodicDateRangeSelect() {
    if(this.periodicDateRange){
      let startDate = moment(this.periodicDateRange[0]).format('YYYY-MM-DD');
      let endDate = moment(this.periodicDateRange[1]).format('YYYY-MM-DD');
      const request={"site_id":this.allocatedSite,"purpose":"getPeriodicReportDetails","start_date":startDate,"end_date":endDate};
      const requestString = JSON.stringify(request);
      this.storage.setItem('periodic_request', requestString);
      this.modelref.close();
      this.router.navigate(['/periodictable']);
    }
  }
  getPeriodicReport(){
      this.loading++;
      let request = { "purpose": "getPeriodicReportList","site_id": "40"};
      this.siteService.getPeriodicReportList(request).then((response: any) => {
        if (response.status == 1) {
         this.allReports = response.allReports;
        }
      }).catch(error => {
      }).finally(() => this.loading--);
  }
  
}
